import { Component, DestroyRef, EventEmitter, HostBinding, inject, Input, OnInit, Output } from '@angular/core'
import { NgTemplateOutlet, DatePipe } from '@angular/common'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { trigger } from '@angular/animations'
import { MatIcon } from '@angular/material/icon'
import { MatTooltipModule } from '@angular/material/tooltip'
import { TranslateModule } from '@ngx-translate/core'
import { MatTableModule } from '@angular/material/table'
import { Store } from '@ngxs/store'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { catchError, startWith, switchMap, tap } from 'rxjs/operators'
import { of, Subject } from 'rxjs'

import { DugnadReminderHistoryResponse } from './interfaces/dugnad-reminder-history-response'
import { DugnadsService } from './../../../../../core/api/dugnads.service'
import { GroupSelectorState } from './../../../../../shared/modules/group-selector/states/group-selector.state'
import { OverlayAnimations } from './../../../../../shared/components/overlay/animations'
import { ReminderType } from '../enums/reminder-type'
import { TableComponent } from '../../../../../shared/modules/table/table.component'
import { SelectedGroup } from '../../../../../core/interfaces/organization/selected-group'
import { OrderStatus } from '../interfaces/order-status'
import { PaidStatus } from '../enums/paid-status'
import { Columns } from '../../../../../core/enums/table/columns'
import { DateFormat } from '../../../../../core/enums/global/date-format'
import { Table } from '../../../../../core/classes/table/table'
import { ToggleUserProfile } from '../../../../../shared/modules/user-profile/state/user-profile.actions'
import { ProfilePictureComponent, UserActivationStatusComponent } from '../../../../../shared/components'
import { ButtonComponent } from '../../../../../shared/components/button/button.component'
import { OverlayModule } from '../../../../../shared/components/overlay/overlay.module'
import { FloatingElementDirective } from '../../../../../shared/directives/floating-element.directive'
import { GetFullNamePipe } from '../../../../../shared/pipes/get-full-name.pipe'
import { LoaderComponent } from '../../../../../shared/modules/loader/loader.component'
import { FadeInComponent } from '../../../../../shared/components/animations/fade-in.component'
import { Constants } from '../../../../../constants/constants'

@Component({
  selector: 'app-dugnad-reminder-history',
  templateUrl: './dugnad-reminder-history.component.html',
  styleUrls: ['./dugnad-reminder-history.component.scss'],
  imports: [
    NgTemplateOutlet,
    TranslateModule,
    MatTableModule,
    MatTooltipModule,
    MatIcon,
    DatePipe,
    OverlayModule,
    TableComponent,
    LoaderComponent,
    FadeInComponent,
    ButtonComponent,
    ProfilePictureComponent,
    UserActivationStatusComponent,
    FloatingElementDirective,
    GetFullNamePipe
  ],
  host: { '[@dugnadReminderHistoryAnimation]': '' },
  animations: [trigger('dugnadReminderHistoryAnimation', OverlayAnimations.detailsPopup)]
})
export class DugnadReminderHistoryComponent implements OnInit {
  @SelectSnapshot(GroupSelectorState.selectedGroup) selectedGroup: SelectedGroup

  @Input() mode: 'overlay' | 'compact' = 'compact'
  @Input() reloadTrigger$: Subject<void> = new Subject()
  @Input() userName: string
  @Input() orderId: number
  @Input() dugnadName: string
  @Input() isConfirmed: boolean
  @Input() orderStatus: OrderStatus
  @Input() paidStatus: PaidStatus

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  table: Table<DugnadReminderHistoryResponse> = new Table([
    Columns.Receiver,
    Columns.Type,
    Columns.Date,
    Columns.Email,
    Columns.PushNotification,
    Columns.SMS
  ])

  private destroyRef = inject(DestroyRef)

  get Columns() {
    return Columns
  }

  get OrderStatus() {
    return OrderStatus
  }

  get PaidStatus() {
    return PaidStatus
  }

  get DateFormat() {
    return DateFormat
  }

  get ReminderType() {
    return ReminderType
  }

  @HostBinding('class.compact') get compact() {
    return this.mode === 'compact'
  }

  constructor(
    private store: Store,
    private dugnadsService: DugnadsService
  ) {}

  ngOnInit() {
    this.reloadTrigger$
      .pipe(
        startWith(null),
        tap(() => (this.table.loading = true)),
        switchMap(() => this.dugnadsService.getDugnadReminders(this.orderId, this.selectedGroup.id)),
        catchError(() => of(Constants.getEmptyPagination())),
        tap((response) => (this.table.data = response.results)),
        tap(() => (this.table.loading = false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe()
  }

  openUserProfile(id: number): void {
    this.store.dispatch(new ToggleUserProfile(true, id))
  }

  onClose(shouldSendNewReminder: boolean): void {
    this.close.emit(shouldSendNewReminder)
  }
}
